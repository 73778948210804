import * as T from 'modules/Marketing/types'

const TestimonialData: T.Testimonial[] = [
  {
    campaignType: ['for-sale-by-owner', 'new-mexico', 'north-carolina'],
    name: 'Angie',
    review:
      "The HomeLister Team was awesome to work with. Every transaction we had was seamless. I would recommend HomeLister to anyone that is looking to sell their property FSBO. They have different levels of services options they offer to meet everyone's requirements.",
    highlight:
      'I would recommend HomeLister to anyone that is looking to sell their property FSBO.',
    savings: 13750,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/fsbo/Angie.jpg`,
  },
  {
    campaignType: ['for-sale-by-owner', 'massachusetts', 'home'],
    name: 'Jeff',
    review:
      'We tried to sell our house on our own for 4 months. 2 weeks after listing with HomeLister we sold our house. They were very detailed in describing my house and getting us listed quickly and I was on my way. Phone calls started coming in. The pricing was very reasonable, it was well worth it.',
    highlight:
      'We tried to sell our house on our own for 4 months. 2 weeks after listing with HomeLister we sold our house.',
    savings: 32400,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/fsbo/Jeff.jpg`,
    location: 'Norwood, MA',
  },

  {
    campaignType: ['for-sale-by-owner', 'new-mexico', 'north-carolina'],
    name: 'Mark',
    review:
      'Jennifer from HomeLister was very helpful. The house sold quickly and Jennifer and HomeLister did a good job in representing us. I would recommend them to anyone wanting to sell their home and not pay huge prices to get listed on MLS.',
    highlight:
      'I would recommend them to anyone wanting to sell their home and not pay huge prices to get listed on MLS.',
    savings: 13200,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/fsbo/Mark.jpg`,
  },
  {
    campaignType: ['for-sale-by-owner', 'delaware'],
    name: 'Yvette',
    review:
      'The HomeLister Team was very professional and the entire process and home sale experience was excellent! I would highly recommend HomeLister to those homeowners who seek to sell their own home.',
    highlight:
      'I would highly recommend HomeLister to those homeowners who seek to sell their own home.',
    savings: 16800,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/fsbo/Yvette.jpg`,
  },
  {
    campaignType: ['for-sale-by-owner', 'new-jersey'],
    name: 'Eileen',
    review:
      'It was easy to sell my home using HomeLister. The friendly staff walked me through every step up to the closing. If you want to save money on the process, then HomeLister is the way you should go. I will definitely use them again for my next sale.',
    highlight: 'It was easy to sell my home using HomeLister.',
    savings: 33000,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/fsbo/Eileen.jpg`,
  },
  {
    campaignType: ['for-sale-by-owner', 'california'],
    name: 'Ari',
    review:
      "We chose HomeLister for our FSBO because of their reputation as well as the many options they offered. We chose the basic package since I had sold other homes before and knew more of the ropes. They consistently communicated with us throughout the sale process. Our home sold within one week, so we didn't even put up a yard sign or require much additional support. We're glad we went with HomeLister.",
    highlight:
      'We chose HomeLister for our FSBO because of their reputation as well as the many options they offered.',
    savings: 59850,
    location: 'Carlsbad, CA',
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/fsbo/Ari.jpg`,
  },
  {
    campaignType: ['mls', 'home'],
    name: 'Wendy',
    review:
      'We listed our home on a Friday with the help of a very responsive HomeLister team. Website was very user friendly and very self explanatory but when I needed a question answered someone was there to answer in chat within seconds. The listing went on our local MLS on a Tuesday. And we had our buyer by Thursday! Our closing was yesterday and we are still in awe.',
    highlight: 'The listing went on our local MLS on a Tuesday. And we had our buyer by Thursday!',
    savings: 23400,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/mls/Wendy.jpg`,
  },
  {
    campaignType: 'mls',
    name: 'Jason',
    review:
      'Awesome way to sell a home. Got us listed in the MLS for a fraction of traditional realtor fees and we sold our home during the COVID-19 pandemic. Give them a try!',
    highlight: 'Got us listed in the MLS for a fraction of traditional realtor fees',
    savings: 11300,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/mls/Jason.jpg`,
  },
  {
    campaignType: 'mls',
    name: 'Bernard',
    review:
      'Selling or buying a house can be stressful and selling on your own can be downright daunting!! Utilizing the service of HomeLister was the best decision I made as a for sale by owner. My friend recommended this service after the great results she had when she sold her home. HomeLister got my home on the MLS which gave it more eyes than I could on my own. They kept in contact with me from listing to closing, and helped me with any questions I had as I navigated the process. Best of all they were efficient and their customer service is impeccable.',
    highlight: 'HomeLister got my home on the MLS which gave it more eyes than I could on my own.',
    savings: 80850,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/mls/Bernard.jpg`,
  },
  {
    campaignType: ['mls', 'georgia'],
    name: 'Julia',
    review:
      "I would highly recommend using HomeLister to market your home. Their website makes it easy to get your home on the local MLS service and offers options for various levels of service. You'll have access to most state real estate forms required. Their agents are licensed nationwide and can assist with your listing verbiage to get the maximum exposure. They also are available for quick responses during the contract offer, negotiations, review and closing processes. Their fees are very reasonable.",
    highlight:
      'Their website makes it easy to get your home on the local MLS service and offers options for various levels of service.',
    savings: 19200,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/mls/Julia.jpg`,
    location: 'Marietta, GA',
  },
  {
    campaignType: 'mls',
    name: 'Mindy',
    review:
      'Jennifer was very helpful. The house sold quickly and Jennifer and HomeLister did a good job in representing us. I would recommend them to anyone wanting to sell their home and not pay huge prices to get listed on MLS.',
    highlight:
      'I would recommend them to anyone wanting to sell their home and not pay huge prices to get listed on MLS.',
    savings: 22500,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/mls/Mindy.jpg`,
  },
  {
    campaignType: ['mls', 'california'],
    name: 'Arturo',
    review:
      'Amazing! Flat fee to get on all the sites to list property. They managed to get the listing on the MLS as well as various listing websites on top of the MLS.',
    highlight:
      'They managed to get the listing on the MLS as well as various listing websites on top of the MLS.',
    savings: 41400,
    location: 'Tarzana, CA',
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/mls/Arturo.jpg`,
  },
  {
    campaignType: ['sell-your-home-fast', 'pennsylvania', 'home'],
    name: 'Corey',
    review:
      'It was the best decision listing with the HomeLister Team. The process was so simple, and I had multiple offers on my home within a few days and we recently just closed. The home was sold for more than a realtor originally appraised it at, plus we saved thousands in realtor fees. Highly recommend their services!',
    highlight: 'I had multiple offers on my home within a few days',
    savings: 12000,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/sell-home-fast/Corey.jpg`,
    location: 'Lake Ariel, PA',
  },
  {
    campaignType: ['sell-your-home-fast', 'delaware'],
    name: 'Michelle',
    review:
      "We worked with Tammy and the HomeLister team to sell our home, and we couldn't have been more pleased! We received multiple offers the first day the house went on the market, thanks in large part to HomeLister team’s incredible work.",
    highlight: 'We received multiple offers the first day the house went on the market',
    savings: 12700,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/sell-home-fast/Michelle.jpg`,
  },
  {
    campaignType: ['sell-your-home-fast', 'new-mexico', 'north-carolina'],
    name: 'Jeanette',
    review:
      'Listing with HomeLister couldn’t have been easier! With their packages, you can have as little help or as much support as you need. They saved me thousands in commissions! My home sold in 4 days! I’ll definitely use them again in the future!',
    highlight: 'My home sold in 4 days!',
    savings: 11100,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/sell-home-fast/Jeanette.jpg`,
  },
  {
    campaignType: ['sell-your-home-fast', 'new-jersey'],
    name: 'Bill',
    review:
      'My motivation to use HomeLister was economic. They were able to immediately help us get our house on numerous websites, most importantly on MLS. Loading photos was straightforward. I saved a lot of commissions. My house was under contract within 8 hours after showing up on MLS.',
    highlight: 'My house was under contract within 8 hours after showing up on MLS.',
    savings: 12700,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/sell-home-fast/Bill.jpg`,
  },
  {
    campaignType: 'sell-your-home-fast',
    name: 'Casey',
    review:
      'HomeLister was very thorough and inexpensive compared to listing with an agent. With the market values going up and down it was impossible to sell my home with an agent and pay them commission. HomeLister had a set fair price to list my home, help me with closing, and I sold my house in one week.',
    highlight: 'I sold my house in one week.',
    savings: 34200,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/sell-home-fast/Casey.jpg`,
  },
  {
    campaignType: ['sell-your-home-fast', 'connecticut', 'home'],
    name: 'Sarah',
    review:
      'I listed the property and got an offer on the first day and sold the home. Great alternative to going to a full service realtor who would have done the same job.',
    highlight: 'got an offer on the first day',
    savings: 43800,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/sell-home-fast/Sarah.jpg`,
    location: 'Stamford, CT',
  },
  {
    campaignType: ['sell-home-online', 'online-realtor', 'new-york', 'home'],
    name: 'Christian',
    review:
      'Our HomeLister experience was exceptional. For a fair fee, we received an MLS listing, an online template that was easy to use for posting the details of our house, professional photography and responsive experience. Our house sold after showing to approximately 20 interested parties. We garnered 8 offers and sold above asking. My wife and I highly recommend Home Lister. HomeLister is a modern day alternative to an overpriced and outdated realtor process.',
    highlight:
      'HomeLister is a modern day alternative to an overpriced and outdated realtor process.',
    savings: 9300,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/sell-home-online/Christian.jpg`,
    location: 'Kenmore, NY',
  },
  {
    campaignType: ['sell-home-online', 'online-realtor'],
    name: 'Lynette',
    review:
      'This was an incredible experience. I loved the website which was extremely user friendly and professional. Every time I had a question or needed assistance I was answered in a timely manner the same day. I loved the chat window feature. Most of all everyone was knowledgeable and friendly to me. It’s an emotional experience selling your home and they were there every step of the way.',
    highlight: 'I loved the website which was extremely user friendly and professional.',
    savings: 16800,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/sell-home-online/Lynette.jpg`,
  },
  {
    campaignType: ['sell-home-online', 'online-realtor', 'new-jersey', 'home'],
    name: 'Derek',
    review:
      'My experience with HomeLister was excellent from beginning to end. The process was efficient and seamless. We were recommended to HomeLister from a neighbor who had used it to sell a home in a nearby neighborhood. His sale was quick and he was very impressed with the ease in which he was able to perform all transactions attached to the process. They were professional and always available if needed.',
    highlight: 'The process was efficient and seamless.',
    savings: 22200,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/sell-home-online/Derek.jpg`,
  },
  {
    campaignType: ['sell-home-online', 'online-realtor', 'delaware'],
    name: 'Tracy',
    review:
      'I used HomeLister to sell my parents home and saved them about $6000! The HomeLister site was so easy to use and I loved being in control of photos to post, price change, scheduling the showings and even how much commission to pay the buyer’s agent. They afforded me every tool needed for a smooth transaction. I even recommended HomeLister to my sister in law and her home sold in 2 weeks! I know when I’m ready to sell, I’ll only use HomeLister.',
    highlight: 'They afforded me every tool needed for a smooth transaction.',
    savings: 8000,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/sell-home-online/Tracy.jpg`,
  },
  {
    campaignType: ['sell-home-online', 'online-realtor', 'texas'],
    name: 'Alex',
    review:
      'HomeLister made everything seamless. Their website is easy to navigate and their responsiveness is unparalleled. Every time I called on an issue, it was quickly resolved or I had somebody from HomeLister contact me in a timely manner',
    highlight: 'HomeLister made everything seamless',
    savings: 14400,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/sell-home-online/Alex.jpg`,
    location: 'Anna, TX',
  },
  {
    campaignType: ['sell-home-online', 'online-realtor', 'maryland', 'washington-dc'],
    name: 'Kimberly',
    review:
      'We had an excellent experience using HomeLister to sell our home. The process was easy and anytime we had a question, a representative was available, knowledgeable and eager to help. I highly recommend the company.',
    highlight: 'We had an excellent experience using HomeLister to sell our home.',
    savings: 40200,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/sell-home-online/Kimberly.jpg`,
  },
  {
    campaignType: ['sell-home-without-realtor', 'oregon'],
    name: 'Greg',
    review:
      'As a seller there is absolutely no reason to use a realtor. We went with the platinum package which cost us $700.00 up front for very professionally looking photos, publishing our listing a lock box and signage. We also paid $2,000 upon closing, saving $10,425.00 which went towards our new home purchase. Tami was absolutely amazing, supportive and providing suggestions throughout the process. Laura was also very responsive and most helpful. We could not have had a better home sales experience.',
    highlight: 'As a seller there is absolutely no reason to use a realtor',
    savings: 10425,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/sell-home-wo-realtor/Greg.jpg`,
    location: 'Lafayette, OR',
  },
  {
    campaignType: ['sell-home-without-realtor', 'florida'],
    name: 'Eric',
    review:
      "We couldn't stand the thought of paying a real estate agent 6% commission, so after hearing about HomeLister from someone in the neighborhood and doing a lot of investigating, we went with them. They were great every step of the way and using your service saved us THOUSANDS of dollars.",
    highlight:
      'They were great every step of the way and using your service saved us THOUSANDS of dollars.',
    savings: 22200,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/sell-home-wo-realtor/Eric.jpg`,
    location: 'Coral Springs, FL',
  },
  {
    campaignType: ['sell-home-without-realtor', 'pennsylvania'],
    name: 'Harriet',
    review:
      "Do this. Save yourself large realtor fees. This was an amazing experience. Professional, helpful, quick, easy and no hidden fees. Amazing. I wish I knew about HomeLister sooner. You won't be sorry!!!!",
    highlight: 'Do this. Save yourself large realtor fees',
    savings: 12000,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/sell-home-wo-realtor/Harriet.jpg`,
    location: 'Philadelphia, PA',
  },
  {
    campaignType: ['sell-home-without-realtor', 'florida'],
    name: 'Shawn',
    review:
      'I used HomeLister to sell 2 homes at the same time.  The process was effortless and I saved over $27,000.00 in commission fees.  If you’re selling your home this is the way to do it.',
    highlight: 'The process was effortless and I saved over $27,000.00 in commission fees.',
    savings: 16800,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/sell-home-wo-realtor/Shawn.jpg`,
    location: 'Pompano Beach, FL',
  },
  {
    campaignType: ['sell-home-without-realtor'],
    name: 'Cathy',
    review:
      'I listed the property and got an offer on the first day and sold the home. Great alternative to going to a full service realtor who would have done the same job.',
    highlight:
      'Great alternative to going to a full service realtor who would have done the same job.',
    savings: 27000,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/sell-home-wo-realtor/Cathy.jpg`,
  },
  {
    campaignType: 'sell-home-without-realtor',
    name: 'Felicia',
    review:
      'It was the best decision listing with the HomeLister Team. The process was so simple, and I had multiple offers on my home within a few days and we recently just closed. The home was sold for more than a realtor originally appraised it at, plus we saved thousands in realtor fees. Highly recommend their services!',
    highlight:
      'The home was sold for more than a realtor originally appraised it at, plus we saved thousands in realtor fees.',
    savings: 9900,
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto/amazon/assets/c/sell-home-wo-realtor/Felicia.jpg`,
  },
  {
    campaignType: 'california',
    name: 'Gina',
    review:
      'I was selling my newer construction home, and I do sales by trade, so I decided to use HomeLister to help me with the legal and technical aspects. It was easy to use the website (and is mobile friendly), to get the house listed on the MLS. They sent me a flyer for an open house as well. I sold my house in 8 days, much faster than anticipated! I opted in for the transaction management, and an agent helped me with every step of the paperwork, backup offers, and explained to me what was within my legal rights, as well as gave me very good advice on certain items. You do technically have an agent helping you sell your house, you just need to host the open houses is the way I look at it. I already recommended HomeLister to a neighbor!',
    highlight: 'I sold my house in 8 days, much faster than anticipated!',
    savings: 47400,
    location: 'Los Angeles, CA',
  },
  {
    campaignType: 'connecticut',
    name: 'Melinda',
    review:
      'The HomeLister Team was very professional and the entire process and home sale experience was excellent! I would highly recommend HomeLister to those homeowners who seek to sell their own home.',
    highlight:
      'The HomeLister Team was very professional and the entire process and home sale experience was excellent!',
    savings: 50450,
    location: 'Wilton, CT',
  },
  {
    campaignType: 'connecticut',
    name: 'Robin',
    review:
      'Made things super easy and they were always there when I needed them, selling my condo was painless, I would definitely recommend HomeLister! They always answered any questions, they were quick to email or call back',
    highlight: 'they were always there when I needed them',
    savings: 294000,
    location: 'Norwalk, CT',
  },
  {
    campaignType: 'florida',
    name: 'Naomi',
    review:
      'HomeLister set everything in motion for me: listing, signage, lockbox, etc. They helped me set up the appointment manager and provided counseling throughout the process. They even stepped in to negotiate on my behalf to ensure my interests were well protected. We could not be more satisfied with the experience and are very appreciative of the thousands of dollars that they saved us.',
    highlight:
      'They even stepped in to negotiate on my behalf to ensure my interests were well protected.',
    savings: 10500,
    location: 'Daytona Beach, FL',
  },
  {
    campaignType: 'georgia',
    name: 'Kelly',
    review:
      'We wanted a very basic service -- MLS listing, professional sign in the yard, and a lock box. We were able to get just that, at an extremely reasonable price through HomeLister. The process was very easy to follow, and quite thorough. Questions were answered quickly and the staff is very friendly.',
    highlight:
      'We were able to get just that, at an extremely reasonable price through HomeLister.',
    savings: 16800,
    location: 'Tucker, GA',
  },
  {
    campaignType: 'georgia',
    name: 'Michael',
    review:
      'Easy process, easy to list, upload photos, and we sold the house within a week! Will use HomeLister for my next sale!',
    highlight: 'we sold the house within a week!',
    savings: 28800,
    location: 'Rosell, GA',
  },
  {
    campaignType: ['maryland', 'washington-dc'],
    name: 'Kristi',
    review:
      'This was my third house I sold on HomeLister. Amazing service! Saved thousands of dollars worth of commission! High quality pictures, great marketing, highly recommended!',
    highlight: 'This was my third house I sold on HomeLister.',
    savings: 27600,
    location: 'Annapolis, MD',
  },
  {
    campaignType: ['maryland', 'washington-dc'],
    name: 'Daniel',
    review:
      'This was my 4th house I sold on HomeLister. Received an offer on the second day. Their website is user friendly. I was able to save close to $9000 worth of commission. I highly recommend HomeLister!',
    highlight: ' I was able to save close to $9000 worth of commission.',
    savings: 8900,
    location: 'Bowie, MD',
  },
  {
    campaignType: 'massachusetts',
    name: 'Jenny',
    review:
      'I would highly recommend the HomeLister team to assist you through the process of selling a property. A very professional service that takes care of all the administration whilst as the owner you retain full control of the transaction. And a la carte services that save you a lot of money.',
    highlight:
      'A very professional service that takes care of all the administration whilst as the owner you retain full control of the transaction.',
    savings: 53550,
    location: 'Hingham, MA',
  },
  {
    campaignType: 'massachusetts',
    name: 'Kevin',
    review:
      'Easy to use with a great web dashboard. They were always very responsive to my calls and emails. The chat function online was great and quick to get a question answered on the fly. Listing, selling, and closing was a breeze plus the savings were huge versus having an agent. I definitely recommend using HomeLister.',
    highlight:
      'Listing, selling, and closing was a breeze plus the savings were huge versus having an agent.',
    savings: 35400,
    location: 'Holliston, MA',
  },
  {
    campaignType: 'new-york',
    name: 'Kelly',
    review:
      'We had an excellent experience selling our house through HomeLister. Any time I had a question, a representative was available, knowledgeable and eager to help. I would highly recommend the company.',
    highlight: 'We had an excellent experience selling our house through HomeLister.',
    savings: 25800,
    location: 'Valhalla, NY',
  },
  {
    campaignType: 'new-york',
    name: 'Lana',
    review:
      'HomeLister was extremely responsive throughout the process. I felt more connected to this team through phone and email than with realtors we’ve used in the past. They were knowledgeable and were able to answer every question from start to finish.',
    highlight:
      'I felt more connected to this team through phone and email than with realtors we’ve used in the past.',
    savings: 16800,
    location: 'Canandaigua, NY',
  },
  {
    campaignType: 'oregon',
    name: 'Paula',
    review:
      'The team was amazing. Everything was taken care of seamlessly and in a timely manner from arranging the photography of the house, brochures, MLS listing and holding my hand through the offer to closing.',
    highlight: 'The team was amazing.',
    savings: 19200,
    location: 'Medford, OR',
  },
  {
    campaignType: 'oregon',
    name: 'Annie',
    review:
      'Easy, peasy.  You can do everything from the comfort of your home or directly from work.  Everything was done online, all documentation online, appointments for showing your home online, closing online, escrow online.  Very efficient, effective and fast! Results are amazing!',
    highlight: 'Results are amazing!',
    savings: 10200,
    location: 'Reedsport, OR',
  },
  {
    campaignType: 'pennsylvania',
    name: 'Cindy',
    review:
      'HomeLister made it easy to list our home ourselves, saving us thousands of dollars in commissions. We had professional photography done, a lawn sign, and were listed on all the major sites and in the MLS. The app for setting up appointments was also extremely helpful. Would definitely recommend it.',
    highlight:
      'HomeLister made it easy to list our home ourselves, saving us thousands of dollars in commissions.',
    savings: 23400,
    location: 'Collegeville, PA',
  },
  {
    campaignType: 'texas',
    name: 'Patricia',
    review:
      'We loved using HomeLister as they helped prepare the house for sale and got the buyers to come - we had our firm offer in 4 days - they were great in every way.',
    highlight: 'we had our firm offer in 4 days',
    savings: 11400,
    location: 'San Antonio, TX',
  },
  {
    campaignType: 'texas',
    name: 'Sabrina',
    review:
      "The HomeLister team was awesome to work with. Every transaction we had was seamless. I would recommend HomeLister to anyone that is looking to sell their property FSBO. They have different levels of services options they offer to meet everyone's requirements.",
    highlight:
      'I would recommend HomeLister to anyone that is looking to sell their property FSBO.',
    savings: 7500,
    location: 'Palacios, TX',
  },
]

const TestimonialDataA: T.Testimonial[] = [
  {
    campaignType: 'homepage-1',
    name: 'Jenifer',
    review:
      'I am so incredibly glad that I discovered HomeLister to sell my house. HomeLister gave me just enough support, and it was a seamless process. Everyone was incredibly professional. The value was outstanding. I saved about $11,000 in commission by doing this, and my house sold in 4 days! I highly recommend them to anyone and everyone who is trying to sell a house right now and wants to save thousands of dollars in commissions.',
    highlight: 'I saved about $11,000 in commission by doing this, and my house sold in 4 days!',
    savings: 11000,
    location: '',
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto,w_200/amazon/assets/c/jenifer.jpeg`,
  },
  {
    campaignType: 'homepage-1',
    name: 'Atef',
    review:
      "I have used HomeLister to sell 5 properties in the last year and a half here in Maryland. I have been able to save close to $80K worth of commission. You pay a flat fee, no hidden fees, doesn't matter if your home is worth $2M or $400K you still pay the same flat fee!!! Can this get any better? Their website is extremely user friendly, they helped me through from A to Z. You are in full control of your home sale. No pushy realtors! I highly recommend them to anyone who doesn't mind to do a little bit of work and save a ton of your hard earned equity!",
    highlight:
      "You pay a flat fee, no hidden fees, doesn't matter if your home is worth $2M or $400K you still pay the same flat fee!!!",
    savings: 80000,
    location: '',
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto,w_200/amazon/assets/c/atef.jpeg`,
  },
  {
    campaignType: 'homepage-1',
    name: 'Annie',
    review:
      "I loved my HomeLister experience. The team was knowledgeable and extremely helpful thru the entire process. I've shared with others and friends have used HomeLister also. The bottom line is you get all you need to professionally sell your house & close the deal as you save a lot of unnecessary costs. Love, love, love HomeLister.",
    highlight:
      'The bottom line is you get all you need to professionally sell your house & close the deal as you save a lot of unnecessary costs.',
    savings: 200000,
    location: '',
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto,w_200/amazon/assets/c/annie.jpeg`,
  },
]

const TestimonialDataHomepageB: T.Testimonial[] = [
  {
    campaignType: 'homepage-b',
    name: 'Cheryl',
    review: `This was the easiest, most pleasant home selling experience ever! It saved me tons of money by not having to pay a seller's agent. Took me a couple hours to measure rooms, upload pics and finish the details. Why pay a realtor 3-4% of your home's sale price for 2 hours of work? HomeLister is a no-brainer to use & worth every penny of their low, low fee! What are you waiting for?`,
    highlight: 'This was the easiest, most pleasant home selling experience ever!',
    savings: 12000,
    location: '',
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto,w_200/amazon/assets/c/jenifer.jpeg`,
  },
  {
    campaignType: 'homepage-b',
    name: 'Atef',
    review:
      "I have used HomeLister to sell 5 properties in the last year and a half here in Maryland. I have been able to save close to $80K worth of commission. You pay a flat fee, no hidden fees, doesn't matter if your home is worth $2M or $400K you still pay the same flat fee!!! Can this get any better? Their website is extremely user friendly, they helped me through from A to Z. You are in full control of your home sale. No pushy realtors! I highly recommend them to anyone who doesn't mind to do a little bit of work and save a ton of your hard earned equity!",
    highlight:
      "You pay a flat fee, no hidden fees, doesn't matter if your home is worth $2M or $400K you still pay the same flat fee!!!",
    savings: 80000,
    location: '',
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto,w_200/amazon/assets/c/atef.jpeg`,
  },
  {
    campaignType: 'homepage-b',
    name: 'Annie',
    review:
      "I loved my HomeLister experience. The team was knowledgeable and extremely helpful thru the entire process. I've shared with others and friends have used HomeLister also. The bottom line is you get all you need to professionally sell your house & close the deal as you save a lot of unnecessary costs. Love, love, love HomeLister.",
    highlight:
      'The bottom line is you get all you need to professionally sell your house & close the deal as you save a lot of unnecessary costs.',
    savings: 200000,
    location: '',
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto,w_200/amazon/assets/c/annie.jpeg`,
  },
]

const TestimonialDataPricingPage: T.Testimonial[] = [
  {
    campaignType: 'pricing-page-1',
    name: 'Mark',
    pronoun: 'his',
    review:
      '"Jennifer from HomeLister was very helpful. The house sold quickly and Jennifer and HomeLister did a good job in representing us. I would recommend them to anyone wanting to sell their home and not pay huge prices to get listed on MLS."',
    highlight:
      'I would recommend them to anyone wanting to sell their home and not pay huge prices to get listed on MLS."',
    savings: 13200,
    location: '',
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto,w_200/amazon/assets/testimonials/mark-1.jpeg`,
  },
  {
    campaignType: 'pricing-page-1',
    name: 'Robin',
    pronoun: 'her',
    review:
      '“Made things super easy and they were always there when I needed them, selling my condo was painless, I would definitely recommend HomeLister! They always answered any questions, they were quick to email or call back”',
    highlight: 'they were always there when I needed them,',
    savings: 29400,
    location: '',
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto,w_200/amazon/assets/testimonials/robin-1.jpeg`,
  },
  {
    campaignType: 'pricing-page-1',
    name: 'Kimberly',
    pronoun: 'her',
    review:
      '“We had an excellent experience using HomeLister to sell our home. The process was easy and anytime we had a question, a representative was available, knowledgeable and eager to help. I highly recommend the company.”',
    highlight: '“We had an excellent experience using HomeLister to sell our home.',
    savings: 40200,
    location: '',
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto,w_200/amazon/assets/testimonials/kimberly-1.jpeg`,
  },
]

const TestimonialDataHowItWorks: T.Testimonial[] = [
  {
    campaignType: 'how-it-works',
    name: 'Darren',
    review:
      'We were completely satisfied every step of the way. When I was unsure of something they got back to me with answers. When we sold our home, they guided us all the way to closing. It was a seamless process. If you want great support and save thousands in real estate fees then sign up with Homelister',
    highlight: '',
    savings: 0,
    location: 'Centerville, MA',
    imgUrl: `${process.env.CDN_BASE_URL}/c_thumb,g_face,w_500,h_500/amazon/assets/how-it-works/customer-profile-1.jpg`,
  },
  {
    campaignType: 'how-it-works',
    name: 'Dianna',
    review:
      'HomeLister ROCKS! Was one of the best decisions I could have made, not hiring a real estate agent. Process was seamless, and the HomeLister agents are professional and knowledgeable. They guided me through the whole process all the way through closing. I will definitely utilize your services again in the future if ever needed.',
    highlight: '',
    savings: 0,
    location: 'Camden, DE',
    imgUrl: `${process.env.CDN_BASE_URL}/c_thumb,g_face,w_500,h_500/amazon/assets/how-it-works/customer-profile-2.jpg`,
  },
  {
    campaignType: 'how-it-works',
    name: 'Jasmine',
    review:
      'AMAZING Experience with HomeLister. My Home sold in 48 hours with no sign in the yard required. I got everything done with the services provided by HomeLister as they were my representative.  #Priceless I would strongly recommend this company for anyone. I also managed my own showings which I enjoyed.',
    highlight: '',
    savings: 200000,
    location: 'Wesley Chapel, FL',
    imgUrl: `${process.env.CDN_BASE_URL}/c_thumb,g_face,w_500,h_500/amazon/assets/how-it-works/customer-profile-3.jpg`,
  },
]

export {
  TestimonialData,
  TestimonialDataA,
  TestimonialDataHomepageB,
  TestimonialDataPricingPage,
  TestimonialDataHowItWorks,
}
